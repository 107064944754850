.child_section_label_with {
  width: calc(40% - 29px);
}

.student_table {
  width: 100%;
}

.student_table th {
  text-align: left;
  padding-left: 10px;
  border: 1px solid white;
  background: #eff1f0;
  height: 30px;
  padding-bottom: 5px;
}

.student_table td {
  text-align: left;
  padding-left: 20px;
  border-bottom: 1px solid #e1e3e2;
  height: 40px;
}
