.skeleton {
  content: " ";
  cursor: progress;
  background: linear-gradient(90deg, #ddda 40%, #efefefaa, #ddda 60%) right /
    300% 100%;
  animation: loading 3s linear infinite;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
