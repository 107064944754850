@media print {
    body * {
        visibility: hidden;
    }

    #root {
        height: 100px;
        max-height: 100px;
        overflow: hidden;
    }

    #receipt-preview, #receipt-preview * {
        visibility: visible !important;
        overflow: visible !important;
    }
}