.w_lecture {
  width: calc(100% - 20px);
}

.w_inner_lecture {
  width: calc(50% - 22px);
}

.w_chapter {
  width: calc(100% - 41px);
}

.w_inner_chapter {
  width: calc(50% - 50px);
}

.w_unit {
  width: calc(100% - 44px);
}

.w_inner_unit {
  width: calc(50% - 74px);
}

.w_inner_last_unit {
  width: calc(20% - 28px);
}

.w_outer_last_table_item {
  width: calc(30% + 35px);
}
