.react-calendar {
  width: 220px;
  max-width: 100%;
  text-align: center;
  color: #7a7a7a;
  border: none;
}

.react-calendar__month-view__weekdays {
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.02em;
  font-family: "Noto Sans JP Regular", "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.react-calendar__month-view__days,
.react-calendar__month-view__days__day--weekend {
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.02em;
  color: #7a7a7a;
  font-weight: 600;
  font-family: "Roboto";
}

.react-calendar__month-view__days__day--neighboringMonth {
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.02em;
  color: #7a7a7a;
  font-weight: 400;
  font-family: "Roboto";
}

abbr[title] {
  text-decoration: none;
}

.react-calendar__title,
.react-calendar__title--now {
  display: inline-block;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: none;
}

.react-calendar__tile:enabled:hover abbr,
.react-calendar__tile:enabled:focus abbr {
  background: #fac46e;
  color: #fff;
  border-radius: 100%;
  padding: 2px;
}

.react-calendar__tile--now {
  background: none;
}
.react-calendar__tile--now abbr {
  background: #ffeac8;
  border-radius: 100%;
  font-weight: bold;
  color: #ff9d00;
  padding: 2px;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: none;
}

.react-calendar__tile--now:enabled:hover abbr,
.react-calendar__tile--now:enabled:focus abbr {
  background: #ffeac8;
  border-radius: 100%;
  font-weight: bold;
  color: #ff9d00;
  padding: 2px;
}

.react-calendar__tile--active {
  background: none;
}

.react-calendar__tile--active abbr {
  background: #fac46e;
  border-radius: 100%;
  font-weight: bold;
  color: white;
  padding: 2px;
}

.react-calendar__tile--active:enabled:hover abbr,
.react-calendar__tile--active:enabled:focus abbr {
  background: #fac46e;
  color: white;
  padding: 2px;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: none;
}

.react-calendar__tile.react-calendar__month-view__days__day {
  padding: 0;
  padding-top: 3px;
  margin-bottom: 5.5px;
}

.react-calendar__month-view__weekdays__weekday {
  padding-top: 3px;
  margin-bottom: 5px;
}

.react-calendar__tile:nth-child(5) abbr {
  background: #faebd2;
  padding: 2px 5px;
}

/* react-datepicker */

.react-datepicker__header {
  background: white;
  border: 0;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  text-align: center;
  color: #7a7a7a;
  padding: 0;
  background: white;
  width: 1.55rem;
  padding-block: 4px;
  text-align: center;
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.22px;
}
.react-datepicker__day:hover {
  background: #fac46e;
  color: white;
}

.react-datepicker__day--selected {
  background: #fac46e !important;
  color: white !important;
  border-radius: 5px !important;
}

.react-datepicker__day--today {
  background: #FAC46E !important;
  color: white;
  border-radius: 100% !important;
  padding-block: 5px !important;
}

.react-datepicker__day--highlighted {
  background: #FAEBD2;
  border-radius: 0;
}

.react-datepicker__day--outside-month {
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.02em;
  color: #bebebe;
  font-weight: 400;
  font-family: "Roboto";
}

/* Full calendar */

.fc {
  border: none !important;
}

.fc-header-toolbar.fc-toolbar {
  display: none;
}

.fc-header-toolbar .fc-toolbar-chunk {
  display: flex;
  align-items: center;
  gap: 15px;
}

.fc-header-toolbar .fc-toolbar-chunk button {
  background-color: transparent;
  color: #7A7A7A;
  border: none;
  padding: 0;
}

.fc-header-toolbar .fc-toolbar-chunk button:hover, .fc-header-toolbar .fc-toolbar-chunk button:active {
  background-color: transparent;
  color: #7A7A7A;
}

.fc-header-toolbar .fc-toolbar-chunk .fc-toolbar-title {
  font-size: 13.6px;
  font-weight: 700;
}

.fc-scrollgrid-sync-table tbody .fc-day {
  padding: 5px 2px;
}

/* Bỏ border cho các tháng và ngày trong bảng */
.fc-daygrid table {
  border: none !important;
}

.fc-scrollgrid-section.fc-scrollgrid-section-header th {
  border: none;
}

.fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid td {
  border: none;
}

.fc-daygrid-day-events {
  margin: 0 4px;
}

/* Bỏ border cho các ô ngày */
.fc-daygrid-day {
  border: none !important;
}

/* Bỏ border cho các sự kiện */
.fc-event {
  width: 87px;
  border-color: transparent;
  border-radius: 5px;
  color: #7A7A7A;
}

/* Bỏ border cho các ô ngày trong tháng khác */
.fc-day-other .fc-daygrid-day-frame {
  background-color: #F9F9F9;
  border-radius: 5px;
  font-weight: 400 !important;
}

/* Bỏ border cho các ô ngày trong tháng hiện tại */
.fc-day-today.fc-daygrid-day {
  background-color: #fff !important;
}

.fc-day-today.fc-daygrid-day .fc-daygrid-day-frame.fc-scrollgrid-sync-inner {
  border: none;
  background-color: #FAC46E !important;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
}

.fc-day-today.fc-day-other.fc-daygrid-day .fc-daygrid-day-frame.fc-scrollgrid-sync-inner .fc-daygrid-day-top a div {
  color: #505050 !important;
}

.fc-day-today.fc-daygrid-day .fc-daygrid-day-frame .fc-daygrid-day-top .fc-daygrid-day-number {
  color: #fff !important;
}

/* Ẩn border cho các ngày trong hàng hiển thị thứ trong tuần */
.fc-col-header-cell.fc-day {
  border: none;
}

/* Set màu sắc cho các thứ trong tuần */
.fc-col-header-cell-cushion {
  color: #7A7A7A;
  font-size: 12px;
  font-weight: 400;
}

.fc-daygrid-day-number {
  color: #7A7A7A;
}
.fc-daygrid-event-harness {
  margin-bottom: 5px;
}

.fc-daygrid-event-harness .fc-event:hover {
  background-color: transparent;
}

.fc-daygrid-event-harness .fc-event {
  height: 18px !important;
}

/* Border top cho các hàng tuần */
.fc-scrollgrid-sync-table tbody tr {
  border-top: 1px solid #EBE4D8;
  padding: 5px 0;
}

.fc-day-sun .fc-scrollgrid-sync-inner .fc-col-header-cell-cushion {
  color: red;
}

.fc-scrollgrid-sync-table tbody tr {
  height: 86px;
}

.fc-event-time {
  display: none;
}

.fc-toolbar-title {
  display: none;
}

.fc-daygrid-day-top {
  font-size: 12px;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  height: 18px;
}
