h1,
h2,
h3,
h4,
h5,
h6,
p {
  all: revert;
  margin: 0;
}

.commercial table {
  width: 100%;
  /* border: 1px solid black; */
}
.commercial table thead th {
  font-weight: 400;
  text-align: left;
}

.commercial table thead tr,
.commercial table tbody tr {
  border-bottom: 5px solid white;
}

.commercial table thead tr th:first-child,
.commercial table tbody tr td:first-child {
  width: 220px;
  height: 40px;
  padding: 12px 15px;
  font-size: 14px;
  color: #2e2e2e;
  font-weight: 600;
  line-height: 100%;
  background-color: #faf7f0;
}

.commercial table thead tr th:nth-child(2),
.commercial table tbody tr td:nth-child(2) {
  padding: 15px 20px;
  font-size: 12px;
  text-align: justify;
  font-weight: 400;
  line-height: 100%;
}

blockquote {
  color: #666;
  margin: 0;
  padding-left: 3em;
  border-left: 0.5em #eee solid;
}