.form_styles input[type='text'],
input[type='number'],
select {
  padding-left: 10px;
  height: 30px;
  border: 1px solid #eff1f0;
}

.form_styles textarea {
  height: 60px;
}

.form_styles input[type='checkbox'] {
  margin-right: 7px;
}

.form_styles input,

textarea {
  font-size: 12px;
  line-height: 100%;
  border: 1px solid #eff1f0;
}
